/** @jsx jsx */
import DonationHeader from 'components/DonationHeader'
import DonationOtherMethods from 'components/DonationOtherMethods'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import {graphql, PageProps} from 'gatsby'
import {Fragment} from 'react'
import {jsx} from 'theme-ui'
import {DonationQuery} from '../graphqlTypes'
import {PageContext} from 'gatsbyTypes'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

interface IDonationPage extends PageProps<DonationQuery, PageContext> {}

const DonationPage: React.FC<IDonationPage> = ({data, pageContext}) => {
  useAutoSelectLanguage(pageContext)
  return (
    <Layout
      sx={{
        position: 'relative',
        bg: '#F5F5F5',
      }}
      navMobileColor="#E5E5E5"
    >
      <SEO title="Donate. Have an impact. Change their lives." description="" />
      <DonationHeader data={data} />

      <DonationOtherMethods
        title="Other payment methods"
        interacBankTitle="Interac bank transfer (From Canada only)"
        interacBankNote={
          <Fragment>
            Use the following emails - in case a password is required please
            enter question:&nbsp;
            <b
              sx={{
                color: '#000',
              }}
            >
              donate
            </b> and answer:&nbsp;
            <b
              sx={{
                color: '#000',
              }}
            >
              ummaty
            </b>
          </Fragment>
        }
        interacBankItems={[
          {
            name: 'Sadaqa - Where most needed',
            email: 'donate@ummaty.org',
          },
          {
            name: 'Zakat',
            email: 'zakat@ummaty.org',
          },
          {
            name: 'Zakat al-Fitr',
            email: 'zakat-alfitr@ummaty.org',
          },
        ]}
        bankWireTransferTitle="Bank wire transfer"
        bankWireTransferItems={[
          {
            label: 'Bank Name',
            content: 'BMO Bank of Montreal',
          },
          {
            label: 'Home Branch Address',
            content: '6-90 Elgin street, Ottawa ON, K1P 0C6, Canada',
          },
          {
            label: 'Account Name',
            content: 'FONDATION UMMATY',
          },
          {
            label: 'SWIFT Code',
            content: 'BOFMCAM2',
          },
          {
            label: 'ABA (Routing Number)',
            content: '026005092',
          },
          {
            label: 'CC Code',
            content: 'CC000124866',
          },
          {
            label: 'Account Number',
            content: '24866-001-1994226',
          },
        ]}
        chequeTitle="Cheque"
        chequeContent={`
              <h3>Write your cheque to:</h3>
              <p>Fondation UMMATY</p>
              <h3>Mail it to:</h3>
              <p>Fondation UMMATY,<br />
              Suite 300 - 6020 rue Jean-Talon E Montréal (Québec),<br />
              H1S3B1 Canada</p>
            `}
      />
    </Layout>
  )
}
export default DonationPage

export const pageQuery = graphql`
  query Donation {
    ...DonationHeader
  }
`
